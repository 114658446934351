.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent,
        var(--color-white),
        transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(7deg);
    transition: (var(--transition));
}

.about__me-image2 {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(-7deg);
    transition: (var(--transition));
}


.about__me-image:hover {
    transform: rotate(0deg);
}

.about__me-image2:hover {
    transform: rotate(0deg);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about__card:hover {
    background: transparent;
    border: 1px var(--color-bg-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content {
    display: grid;
    place-items: center;
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

* {box-sizing: border-box;}

body {
    background-color: var(--color-bg); 
    color: var(--color-white);
}

// header {
//   background-color: rgba(var(--color-bg),.5);
//   font-size: 1.5em;
//   text-align: center;
//   padding: 1em;
// }

.panel-wrapper {
  position: relative;
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
  max-height: max-content;
}

.btn2 {
    color: var(--color-white);
    background: transparent;
    border-color: transparent;
    width: max-content;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: var(--transition);
    margin-bottom: 1rem;
    font-size: 0.5 rem;
}

.btn2:hover {
    color: var(--color-primary);
    border-color: transparent;
}

.show, .hide {
  position: absolute;
  bottom: .25rem;
  z-index: 100;
  text-align: center;
}

.hide {
    display: none;
}

.show {
    bottom: 2rem;
}


.show:target {display: none;}
.show:target ~ .hide {display: block;}
.show:target ~ .panel {
   max-height: max-content; 
}
.show:target ~ .fade {
  display: none;
}

.panel {
  position: relative;
  margin: 2em auto;
  width: 90%;
  max-height: 300px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.fade {
  background: linear-gradient(to bottom, rgba(31, 31, 56, 0) 25%,rgba(31, 31, 56, 1) 75%);
  height: 300px;
  width: 100%;
  margin-top: -300px;
  position: relative;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
   .about__container{
    grid-template-columns: 1fr;
    gap: 0;
   }

   .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
   }

   .about__content {
    justify-content: center;
    text-align: center;
   }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .about__me{
        margin: 0 auto 3rem;
        width: 65%;
    }

    .about__cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1rem;
    }

    .about__content {
        text-align: center;
        
    }

    .about__content p{
        margin: 1.5rem 0;
    }

    .panel-wrapper {
        width: 95%
    }
}