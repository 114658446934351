
header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ====================== CTA ====================== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ====================== SOCIALS ====================== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials a {
    size: 5rem;
    color: var(--color-white);
}

.header__socials a :hover {
    color: var(--color-primary);
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ====================== ME ====================== */
.me {
    background: linear-gradient(var(
        --color-primary), transparent);
    width: 22rem;
    height: 25rem;
    position: absolute;
    margin-top: 4rem;
    left: calc(50% - 11rem);
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
}

.image-container img {
    margin-top: 4rem;
    transition: transform 0.3s ease; /* Add a smooth transition */
  }
  
.image-container:hover img {
    margin-top: 4rem;
    transform: scale(1.2); /* Increase the size of the image on hover */
}

/* ====================== ME ====================== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    color: var(--color-white);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .me {
        padding: 5rem 0.25rem 0;
        height: 51vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}
