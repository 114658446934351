.modal__background {
   width: 100%;
   height: 100%;
   min-width: 100vw;
   min-height: 100vh;
   background-color: var(--color-bg-darken);
   position: fixed;
   top: 0%;
   left: 0%;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   min-height: 100vh;
}

.modal__container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   color: var(--color-white);
   background-color: var(--color-bg);
   box-shadow: rgba(0,0,0,.55) 0rem .5rem 1rem;
   width: 40vw;
   border: 2px solid var(--color-primary);
   padding: 2rem;
   border-radius: 2rem;
   overflow: hidden;
   gap: 1rem;
   justify-content: center;
   align-items: center;
   text-align: center;
}

.modal__close {
    display: flex;
    justify-content: flex-end;
    margin: -1.5rem;
}
.modal__close .btn {
    color: var(--color-white);
    background-color: transparent;
    border: none;
}

.modal__close .btn:hover {
    color: var(--color-light);
    background: transparent;
}

.modal__title {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.modal__body {
    margin: 1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .modal__container {
        width: var(--container-width-md);
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .modal__container {
        width: var(--container-width-sm);
    }
}