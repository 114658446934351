header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    text-align: center;
}

h1 {
    color: var(--color-white);
}

h3 {
    color:var(--color-light)
}

h4 {
    color:var(--color-white)
}
