.services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 2rem 2rem 0 0;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
}
.service__list li {
    display: flex;
    margin: 1rem;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.preaction {
    margin: .5rem;
    margin-bottom: 2.5rem;
    display: grid;
    grid-template-columns: 1fr, 2fr;
    gap: .2rem;
    justify-content: center;
    text-align: center;
}

.action {
    margin: .5rem;
    margin-bottom: 2.5rem;
    display: grid;
    grid-template-columns: 1fr, 2fr;
    gap: .5rem;
    justify-content: center;
    text-align: center;
}

.sidebyside h2, .sidebyside h6{
    display: inline;
    vertical-align: baseline;
}

.preaction h2 {
    color: var(--color-primary)
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.service__list p {
    font-size: 0.9rem;
}

p {
    text-align: center;
    justify-content: center;
}

.service__list p {
    text-align: left;
    font-size: 1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .services__container{
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
    }
    
    .service__head h3 {
        font-size: .8rem;
    }
    
    .service__list {
        padding: .5rem;
    }

    .service__list li {
        display: flex;
        margin: .5rem;
        gap: .5rem;
        margin-bottom: 0.8rem;
    }

 }
 
 /* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
 @media screen and (max-width: 600px) {
    .services__container{
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .service__head h3 {
        font-size: 1rem;
    }

    .service__list {
        padding: 1.5rem;
    }

    .service__list li {
        display: flex;
        margin: .25rem;
        gap: .75rem;
        margin-bottom: 0.8rem;
    }
 }