@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
/*  --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
 */
:root {
    --color-bg:  #1F1F38;
    --color-bg-variant: #2c2c6c;
    --color-bg-darken: rgba(24,24,44,0.8);
    --color-primary: #4DB5FF;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: none; 
}
/* Can add bg image later */

/* ================ GENERAL STYLES ================ */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h1 {
    font-size: 4rem;
}

h4 {
    font-size: 1.1rem;
    font-style: italic;
}

h6 {
    font-size: 0.8 rem;
    font-style: italic;
}

section {
    margin-top: 8rem;
    height: 100%;
    min-height: 100vh;
    margin-bottom: 2rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

.btn-primary-variant {
    background: var(--color-primary-variant);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.brandon__text {
    width: 100%;
    
}

.brandon__text-image {
    overflow: hidden;
    transform: translate(0, 7rem);
    transition: (var(--transition));


    mask-image: radial-gradient(
        ellipse 80% 45%,
        var(--color-bg) 5%,
        var(--color-bg) 20%,
        transparent 80%,
        transparent
    );
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.5rem;
    }

    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }

    .brandon__text {
     transform: translate(0, 9rem);
    }
    
    .brandon__text-image {
        transform: translate(0, 5rem);
        
            mask-image: radial-gradient(
                ellipse 80% 55%,
                var(--color-bg) 0%,
                var(--color-bg) 15%,
                transparent 80%,
                transparent
            );
    }
}
